import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  getInPlans: "/api/WarehouseService/InWarehousePlan/GetListPaged",

  getWarehouseNos: "/api/storage/inventory/WarehouseNos/",
  //库存仓库列表
  getInventoryWarehouses: "/api/WarehouseService/Box/GetInventoryWarehouse",
  reconnectProduct: "/api/WarehouseService/Box/UpdateProductInfo",

  getProducts: "/api/ProductService/Product/GetAutoListPaged",
  getInventoryList: "/api/WarehouseService/Box/GetUserInventoryListPaged",

  getInPlanDetail: "/api/WarehouseService/InWarehousePlan/Get",
  getCheckResult: "/api/WarehouseService/Warehouse/CheckHasWarehouseType",

  getInPlanFirstStep: "/api/WarehouseService/InWarehousePlan/GetFirstStep",
  createInPlan: "/api/WarehouseService/InWarehousePlan/Create",
  updateInPlan: "/api/WarehouseService/InWarehousePlan/Update",
  confirmInPlan: "/api/WarehouseService/InWarehousePlan/UpdateConfirm",

  updateInPlanName:
    "/api/WarehouseService/InWarehousePlan/UpdateInWarehousePlanName",
  //发运
  updateTransInfo: "/api/WarehouseService/InWarehousePlan/UpdateTransInfo",
  feePay:"/api/WarehouseService/InWarehousePlan/PayPlan",
  getTrackList: "/api/WarehouseService/InWarehousePlan/GetAllTraceList",
  //更新追踪号
  updateTrackNos:
    "/api/WarehouseService/InWarehousePlan/UpdateInWarehousePlanTraceNo",

  //#region 入库计划详情
  updatePlanErrorCondition:
    "/api/WarehouseService/InWarehousePlan/UpdateProductErrorStatus",
  //#endregion

  //#region 出库计划
  cancelOutPlan: "/api/WarehouseService/OutWarehousePlan/UpdateCancelPlan",
  getOutPlans: "/api/WarehouseService/OutWarehousePlan/GetListPaged",
  getUserOfInventoryWarehouseInfos:
    "/api/WarehouseService/Box/GetInventoryWarehouseWithAdress",
  // 判断是否支持对应仓库功能
  checkSupportConsignment:
    "/api/WarehouseService/Warehouse/CheckHasWarehouseTypeWithReturn",
  getBetweenWarehouse: "/api/WarehouseService/Warehouse/GetList",

  getFbaOrFbmWarehouse: "/api/BasicDataService/PlatformAddress/GetList",

  getHitTorrServes: "/api/WarehouseService/PackMaterialConfig/GetList",
  getBoxList: "/api/WarehouseService/Box/GetUserSelectInventoryListPaged",
  createOutPlan: "/api/WarehouseService/OutWarehousePlan/Create",

  getOutPlanDetails: "/api/WarehouseService/OutWarehousePlan/Get",
  getLogisticDetail: "/api/WarehouseService/WarehouseLogistics/GetLogisticsByOutWarehouseDetail",
  
  updateOutPlanName:
    "/api/WarehouseService/OutWarehousePlan/UpdateOutWarehousePlanName",
  confirmOutPlan: "/api/WarehouseService/OutWarehousePlan/UpdateConfirm",
  anewInWarehouse: "/api/WarehouseService/OutWarehousePlan/UpdateReWarehouse",
  getFreightEstimate:
    "/api/WarehouseService/WarehouseLogistics/GetLogisticsByOutWarehouse",

  //添加箱信息-选择出库产品Modal search----弃用了!
  getOutProducts:
    "/api/WarehouseService/InventoryStatistics/GetUserStorageOutWhPaged",
  //添加箱信息-选择出库产品Modal Add----弃用了!
  getOutProductBoxInfos:
    "/api/WarehouseService/Box/GetWhboxAndBoxInfoByProduct",
    //完善产品信息(箱里添加产品)
    addProductToBox:"/api/WarehouseService/Box/CreateBoxProduct",
  //#endregion

  // 取消计划
  updateCancelPlan: "/api/WarehouseService/InWarehousePlan/UpdateCancelPlan",

  // // 确认出库-获取自提费
  // getSelfWithdrawalFee:
  //   "/api/WarehouseService/OutWarehousePlan/GetSelfWithdrawalFee",

  //获得出库计划的费用
  getOutPlanFee:"/api/WarehouseService/OutWarehousePlan/GetFee",
  // 导出存储仓库存excel
  exportInventoryList: "api/WarehouseService/Box/ExportInventoryList",
  // 导出产品在库时间
  exportInventoryListWithStockAge:
    "/api/WarehouseService/Box/ExportInventoryListWithStockAge",
  // 导出存储仓-出库计划
  exportOutWarehousePlan: "/api/WarehouseService/OutWarehousePlan/ExportOutwarehousePlan",
  //卡车获得报价
  getTrackLogisticsFee:
    "/api/WarehouseService/WarehouseLogistics/GetLogisticsByOutWarehouseFee",
  
  // 下载产品模板
  getGenerateTemplate: "/api/WarehouseService/InWarehousePlan/GenerateTemplate",
  // 导入产品模板
  parseProductExcelData: "/api/WarehouseService/InWarehousePlan/ParseExcelData",
  // 导出存储仓入库计划
  exportStorageInWarehousePlan: "/api/WarehouseService/InWarehousePlan/ExportStorageInWarehousePlan",
  // 导出配送仓入库计划
  exportDelegateInWarehousePlan: "/api/WarehouseService/InWarehousePlan/ExportDelegateInWarehousePlan",

  getFee:"/api/WarehouseService/InWarehousePlan/GetFee",

  //库存列表更新箱的产品数据
  updateProductInfo:"/api/WarehouseService/Box/UpdateProductInfo",

  //批量上传箱标
  batchUploadLabels:"/api/BasicDataService/AliyunOSS/OutWarehouseUploadToTemp",
};



export const feePay = (id) => {
  return client.request({
    url: Api.feePay,
    data: {id},
    method: "post",
  });
};

export const getWarehouseNos = (data) => {
  return client.request({
    url: Api.getWarehouseNos,
    data: data,
    method: "post",
  });
};

export const getInventoryWarehouses = (data) => {
  return client.request({
    url: Api.getInventoryWarehouses,
    data: data,
    method: "post",
  });
};

export const getInventoryList = (data) => {
  return client.request({
    url: Api.getInventoryList,
    data: data,
    method: "post",
  });
};

export const reconnectProduct = (whboxId, productId) => {
  return client.request({
    url: Api.reconnectProduct,
    data: { whboxId, productId },
    method: "post",
    disableAutoRedirect: true,
  });
};

export const getInPlans = (data) => {
  return client.request({
    url: Api.getInPlans,
    data: data,
    method: "post",
  });
};

export const getInPlanDetail = (data) => {
  return client.request({
    url: Api.getInPlanDetail,
    params: data,
    method: "get",
  });
};

export const getCheckResult = (data) => {
  return client.request({
    url: Api.getCheckResult,
    data: data,
    method: "post",
  });
};

export const getProducts = (data) => {
  return client.request({
    url: Api.getProducts,
    data: data,
    method: "post",
  });
};

export const createInPlan = (data) => {
  return client.request({
    url: Api.createInPlan,
    data,
    method: "POST",
  });
};

export const updateInPlan = ({ id, planName, to, productList, from }) => {
  return client.request({
    url: Api.updateInPlan,
    data: { id, planName, to, productList, from },
    method: "post",
  });
};

export const getInPlanFirstStep = (data) => {
  return client.request({
    url: Api.getInPlanFirstStep,
    params: data,
    method: "get",
  });
};

export const confirmInPlan = ({ createCwsTransPlan, productList, id }) => {
  return client.request({
    url: Api.confirmInPlan,
    data: { createCwsTransPlan, productList, id },
    method: "post",
  });
};

export const updateInPlanName = (data) => {
  return client.request({
    url: Api.updateInPlanName,
    data: data,
    method: "post",
  });
};

export const updateTransInfo = ({ logisticsType, deliveryType, id,isPay }) => {
  return client.request({
    url: Api.updateTransInfo,
    data: { logisticsType, deliveryType, id,isPay },
    method: "post",
  });
};

export const getTrackList = (data) => {
  return client.request({
    url: Api.getTrackList,
    params: data,
    method: "get",
  });
};

export const updateTrackNos = ({ id, productList }) => {
  return client.request({
    url: Api.updateTrackNos,
    data: { id, productList },
    method: "post",
  });
};

export const cancelOutPlan = (planId) => {
  return client.request({
    url: Api.cancelOutPlan,
    data: { id: planId },
    method: "post",
  });
};

export const getOutPlans = (data) => {
  return client.request({
    url: Api.getOutPlans,
    data: data,
    method: "post",
  });
};

export const getUserOfInventoryWarehouseInfos = (data) => {
  return client.request({
    url: Api.getUserOfInventoryWarehouseInfos,
    data: data,
    method: "post",
  });
};

export const checkSupportConsignment = (data) => {
  return client.request({
    url: Api.checkSupportConsignment,
    data: data,
    method: "post",
  });
};

export const getBetweenWarehouse = (data) => {
  return client.request({
    url: Api.getBetweenWarehouse,
    data: data,
    method: "post",
  });
};

//1 : 亚马逊, 2 : 美客多
export const getFbaOrFbmWarehouse = (data) => {
  return client.request({
    url: Api.getFbaOrFbmWarehouse,
    data: data,
    method: "post",
  });
};

export const updatePlanErrorCondition = (data) => {
  return client.request({
    url: Api.updatePlanErrorCondition,
    data: data,
    method: "post",
  });
};

export const getHitTorrServes = (data) => {
  return client.request({
    url: Api.getHitTorrServes,
    data: data,
    method: "post",
  });
};

export const getBoxList = (data) => {
  return client.request({
    url: Api.getBoxList,
    data: data,
    method: "post",
  });
};

export const createOutPlan = (data) => {
  return client.request({
    url: Api.createOutPlan,
    data: data,
    method: "post",
  });
};

export const getOutPlanDetails = (data) => {
  return client.request({
    url: Api.getOutPlanDetails,
    params: data,
    method: "get",
  });
};

export const getLogisticDetail = (logisticId,planId) => {
  return client.request({
    url: Api.getLogisticDetail,
    data: {logisticId,planId},
    method: "post",
  });
};

export const updateOutPlanName = (data) => {
  return client.request({
    url: Api.updateOutPlanName,
    data: data,
    method: "post",
  });
};

export const confirmOutPlan = (data) => {
  return client.request({
    url: Api.confirmOutPlan,
    data: data,
    method: "post",
  });
};

export const anewInWarehouse = (data) => {
  return client.request({
    url: Api.anewInWarehouse,
    data: data,
    method: "post",
  });
};

export const getFreightEstimate = (data) => {
  return client.request({
    url: Api.getFreightEstimate,
    data: data,
    method: "post",
  });
};

export const getOutProducts = ({
  productId,
  warehouseId,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getOutProducts,
    data: { productId, warehouseId, skipCount, maxResultCount, sorting },
    method: "post",
  });
};

export const getOutProductBoxInfos = ({
  warehouseId,
  productId,
  boxCount,
  hasChooseWhboxIds,
}) => {
  return client.request({
    url: Api.getOutProductBoxInfos,
    data: { warehouseId, productId, boxCount, hasChooseWhboxIds },
    method: "post",
  });
};

export const addProductToBox = (
  whBoxId,
  warehouseId,
  items,
) => {
  return client.request({
    url: Api.addProductToBox,
    data: {  
      whBoxId,
      warehouseId,
      items, },
    method: "post",
  });
};


export const updateCancelPlan = (data) => {
  return client.request({
    url: Api.updateCancelPlan,
    data,
    method: "post",
  });
};

export const getOutPlanFee = ({id,transDeliverType,logistics}) => {
  return client.request({
    url: Api.getOutPlanFee,
    data:{id,transDeliverType,logistics},
    method: "POST",
  });
};

export const exportInventoryList = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportInventoryList);
};

export const exportInventoryListWithStockAge = () => {
  return urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.exportInventoryListWithStockAge
  );
};

export const exportOutWarehousePlan = () => {
  return urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.exportOutWarehousePlan
  );
};

export const getTrackLogisticsFee = ({
  serviceLevel,
  accessorials,
  quoteTypeId,
  logisticId,
  planId,
}) => {
  return client.request({
    url: Api.getTrackLogisticsFee,
    data: {
      serviceLevel,
      accessorials,
      quoteTypeId,
      logisticId,
      planId,
    },
    method: "post",
  });
};

export const getGenerateTemplate = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.getGenerateTemplate);
};

export const parseProductExcelData = (data) => {
  return client.request({
    url: Api.parseProductExcelData,
    data,
    method: "POST",
  });
};

export const exportStorageInWarehousePlan = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportStorageInWarehousePlan);
};

export const exportDelegateInWarehousePlan = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportDelegateInWarehousePlan);
};


export const getFee = params => {
  return client.request({
    url: Api.getFee,
    params,
    method: "GET",
  });
};

export const updateProductInfo = (whBoxId,warehouseId,items) => {
  return client.request({
    url: Api.updateProductInfo,
    data:{whBoxId,warehouseId,items},
    method: "POST",
  });
};

export const batchUploadLabels = (data) => {
  return client.request({
    url: Api.batchUploadLabels,
    data: data,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};