<template>
  <Content>
    <template #contentBody>
      <a-spin size="large" :spinning="pageLoading">
        <a-row v-if="plan.outWarehouseStatus == outPlanStatusEnum.inThePlan">
          <a-col :span="24">
            <a-steps :current="2" style="max-width: 1800px" size="small">
              <a-step :title="$t('warehouse.select_out_warehouse_way')" />
              <a-step :title="$t('warehouse.add_box_info')" />
              <a-step :title="$t('warehouse.confirm_out_plan')" />
              <a-step :title="$t('common.completed')" />
            </a-steps>
          </a-col>
        </a-row>
        <!-- 仓库信息 -->
        <div class="inputs mt-3">
          <a-row :gutter="[0, 12]">
            <div class="ant-form-inline">
              <a-form-item :label="$t('warehouse.out_plan_name')">
                <span v-if="!isChangingPlanName">{{ plan.planName }}</span>
                <a-input
                  v-else
                  ref="refInputPlanName"
                  size="small"
                  @pressEnter="handleSavePlanName"
                  @focus="
                    (event) => {
                      event.currentTarget.select();
                    }
                  "
                  v-model:value="plan.planName"
                />
              </a-form-item>
              <a-form-item>
                <a-button
                  v-if="!isChangingPlanName"
                  type="primary"
                  size="small"
                  @click="handleButtonAlterPlanName"
                  ghost
                  >{{ $t("account.modify") }}</a-button
                >
                <a-button
                  v-else
                  type="primary"
                  size="small"
                  @click="handleSavePlanName"
                  :loading="buttonSavePlanNameLoading"
                  >{{ $t("common.save") }}</a-button
                >
              </a-form-item>
              <a-form-item>
                <a-button
                  v-if="isChangingPlanName"
                  type="primary"
                  size="small"
                  @click="handleCancelAlterPlanName"
                  ghost
                  >{{ $t("common.cancel") }}</a-button
                >
              </a-form-item>
            </div>
            <a-col :span="24">
              <label>{{ $t("warehouse.plan_no") }}:</label>
              {{ plan.planNo }}
            </a-col>
            <a-col :span="24">
              <label>{{ $t("warehouse.out_warehouse_no") }}:</label>
              {{ plan.inventoryWarehouseAddress.no }}
            </a-col>
            <a-col :span="24">
              <label>{{ $t("warehouse.warehouse_address") }}:</label>
              {{ getAddress(plan.inventoryWarehouseAddress) }}
              <span class="ml-5" v-if="plan.inventoryWarehouseAddress"
                >{{ $t("logistics.contacts") }}:
                {{ getLinkman(plan.inventoryWarehouseAddress) }}</span
              >
            </a-col>
            <a-col :span="24">
              <label>{{ $t("warehouse.out_warehouse_type") }}:</label>
              {{ $t($enumLangkey("outWarehouseType", plan.outWarehouseType)) }}
            </a-col>

            <!-- 目标仓库代码(仓库间调拨有) -->
            <a-col
              v-if="
                plan.outWarehouseType ==
                  outWarehouseTypeEnum.betweenWarehouseAllocate ||
                plan.outWarehouseType ==
                  outWarehouseTypeEnum.replaceSendWarehouseEntry
              "
              :span="24"
            >
              <label>{{ $t("warehouse.target_warehouse_code") }}:</label>
              {{ plan.targetAddress.no }}
            </a-col>

            <!-- FBA仓库代码 -->
            <a-col
              v-if="plan.outWarehouseType == outWarehouseTypeEnum.amazonFba"
              :span="24"
            >
              <label>{{ $t("warehouse.fba_warehouse_code") }}:</label>
              {{ plan.targetAddress.name }}
            </a-col>

            <!-- FBm仓库代码 -->
            <a-col
              v-if="plan.outWarehouseType == outWarehouseTypeEnum.mercadolibre"
              :span="24"
            >
              <label>{{ $t("warehouse.fbm_warehouse_code") }}:</label>
              {{ plan.targetAddress.name }}
            </a-col>

            <!-- 发至-->
            <a-col
              v-if="plan.outWarehouseType == outWarehouseTypeEnum.otherAddress"
              :span="24"
            >
              <label>{{ $t("warehouse.destination") }}:</label>
              {{ plan.targetAddress.name }}
            </a-col>

            <!-- 运送地址(本地配送仓没有) -->
            <a-col
              v-if="
                plan.outWarehouseType !=
                  outWarehouseTypeEnum.replaceSendWarehouseEntry &&
                plan.outWarehouseType != outWarehouseTypeEnum.destruction
              "
              :span="24"
            >
              <label>{{ $t("warehouse.transport_goods_address") }}:</label>
              {{ getAddress(plan.targetAddress) }}
              <span v-if="plan.targetAddress.linkManPhone">
                (+{{ plan.targetAddress.phoneCountryCode }}
                {{ plan.targetAddress.linkManPhone }})
              </span>
              <a-tag
                v-if="
                  plan.targetAddress.countryId !=
                  plan.inventoryWarehouseAddress.countryId
                "
                color="warning"
                >{{ $t("warehouse.international_transport") }}</a-tag
              >
              <template
                v-if="
                  plan.outWarehouseType ==
                  outWarehouseTypeEnum.betweenWarehouseAllocate
                "
              >
                <span class="ml-5" v-if="plan.targetAddress"
                  >{{ $t("logistics.contacts") }}:
                  {{ getLinkman(plan.targetAddress) }}</span
                >
              </template>
            </a-col>

            <a-col
              :span="24"
              v-if="plan.outWarehouseType == outWarehouseTypeEnum.amazonFba"
            >
              <label>{{ $t("warehouse.amazon_shipping_number") }}:</label>
              {{ plan.amazonShippingNumber }}
            </a-col>
            <a-col
              :span="24"
              v-if="plan.outWarehouseType == outWarehouseTypeEnum.mercadolibre"
            >
              <label>{{ $t("warehouse.seller_id") }}:</label>
              {{ plan.amazonShippingNumber }}
            </a-col>

            <a-col
              :span="24"
              v-if="plan.outWarehouseType == outWarehouseTypeEnum.amazonFba"
            >
              <label>{{ $t("warehouse.shipment_tracking_number") }}:</label>
              {{ plan.shipmentTrackingNumber }}
            </a-col>
            <a-col
              :span="24"
              v-if="plan.outWarehouseType == outWarehouseTypeEnum.mercadolibre"
            >
              <label>{{ $t("warehouse.warehousing_number") }}:</label>
              {{ plan.shipmentTrackingNumber }}
            </a-col>
          </a-row>
        </div>
        <!-- 列表 -->
        <div class="table mt-3">
          <a-table
            :columns="columns"
            :data-source="plan.tableList"
            size="small"
            :pagination="{
              defaultPageSize: defaultPageSize,
              hideOnSinglePage: true,
              current: currentPageIndex,
              position: 'both',
            }"
            @change="
              (page) => {
                currentPageIndex = page.current;
              }
            "
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            :expandIconAsCell="false"
            :expandIconColumnIndex="2"
          >
            <template #serialNumber>
              <span>
                {{ $t("warehouse.serial_number") }}
              </span>
            </template>
            <template #boxNoTitle>{{ $t("warehouse.box_no") }}</template>
            <template #boxNo="{ record }">
              <a-row :gutter="[12, 12]">
                <a-col>
                  <div>{{ record.box.boxNo }}</div>
                  <div>
                    <small>{{ record.box.selfBoxNo ?? "-" }}</small>
                  </div>
                </a-col>
                <a-col>
                  <CTagMixedOrSingle
                    :productKindCount="
                      record.box?.container?.productKindCount * 1
                    "
                  ></CTagMixedOrSingle>
                </a-col>
              </a-row>
            </template>

            <template #containerCount="{ record }">
              <div v-if="record.box?.container?.containerCount">
                {{ record.box?.container?.containerCount }} Unit
              </div>
              <div v-else>-</div>
            </template>

            <template #out_warehouse_info="{ record }">
              <a-row>
                <a-col>
                  {{ $t("warehouse.out_warehouse_encasement_size") }}:
                </a-col>
                <a-col>
                  <span v-if="record.box?.container?.containerLength">
                    {{ record.box?.container?.containerLength }}x{{
                      record.box?.container?.containerWidth
                    }}x{{ record.box?.container?.containerHeight }} cm</span
                  >
                  <span v-else>-</span>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  {{ $t("warehouse.out_warehouse_encasement_weight") }}:
                </a-col>
                <a-col>
                  <span v-if="record.box?.container?.containerWeight">
                    {{ gToKg(record.box?.container?.containerWeight) }} kg</span
                  >
                  <span v-else>-</span>
                </a-col>
              </a-row>
            </template>

            <template #lableServe>
              <span
                v-if="
                  plan.outWarehouseType !=
                  outWarehouseTypeEnum.replaceSendWarehouseEntry
                "
              >
                {{ $t("warehouse.label_serve_paid") }}
              </span>
              <span v-else>
                {{ $t("warehouse.label_serve") }}
                <span v-if="plan.isWarehouseLabeling"
                  >({{ $t("warehouse.paid") }})</span
                >
              </span>
            </template>

            <template #lableServeCustom="{ record }">
              <div>
                <div
                  v-if="
                    plan.outWarehouseType ==
                    outWarehouseTypeEnum.replaceSendWarehouseEntry
                  "
                >
                  {{
                    $t(
                      $enumLangkey(
                        "labelDispose",
                        record.productLabelProcessing
                      )
                    )
                  }}
                </div>
                <template v-else>
                  <template v-if="record.serviceLabelImgUrl">
                    <div>{{ $t("warehouse.outer_box_label") }}</div>
                    <CFileDisplay
                      :fileUrl="record.serviceLabelImgUrl"
                      :useLocal="true"
                    ></CFileDisplay>
                    <div>{{ record.serviceLabelNo }}</div>
                  </template>
                  <span v-else>-</span>
                </template>
              </div>
            </template>

            <template #expandIcon="{ expanded, record, onExpand }">
              <CHaveProductInformation
                :disabled="!record.box.items || record.box.items?.length == 0"
                :open="expanded"
                @click="(e) => onExpand(record, e)"
              />
            </template>
            <template #expandedRowRender="{ record }">
              <a-table
                :columns="innerColumns"
                :data-source="record.box.items"
                :pagination="false"
                :row-key="(record, index) => index"
                size="small"
              >
                <template #product_info="{ record }">
                  <a-row :gutter="[12, 12]">
                    <a-col>
                      <CFileDisplay :fileUrl="record.productImgUrl" />
                    </a-col>
                    <a-col>
                      {{ record.productName }}
                      <div>
                        {{ record.productNo }}
                      </div>
                    </a-col>
                  </a-row>
                </template>
                <template #count="{ record }">
                  {{ record.containerCount }} Unit
                </template>
              </a-table>
            </template>

            <template #footer>
              <a-row type="flex" justify="start" class="mr-5" :gutter="32">
                <a-col
                  >{{ $t("common.total") }}: {{ plan.totalBox
                  }}{{ $t("warehouse.box") }}</a-col
                >
                <a-col
                  >{{ $t("warehouse.out_total_volume") }}:
                  {{ plan.outTotalVolume }}m<sup>3</sup></a-col
                >
                <a-col
                  >{{ $t("warehouse.out_total_weight") }}:
                  {{ plan.outTotalWeight }}kg</a-col
                >

                <a-col>
                  {{ $t("warehouse.transport_characteristics") }}:
                  <a-tag
                    color="default"
                    v-for="item in plan.transportCharacteristics"
                    :key="item"
                  >
                    {{ $t($enumLangkey("transportCharacteristics", item)) }}
                  </a-tag>
                </a-col>

                <a-col
                  v-if="
                    plan.outWarehouseType !=
                      outWarehouseTypeEnum.replaceSendWarehouseEntry &&
                    plan.outWarehouseType != outWarehouseTypeEnum.destruction
                  "
                >
                  <a-typography-text
                    v-if="plan.isSelectHitServe != 0"
                    type="danger"
                    >{{ $t("warehouse.selected_pallet_packing_serve") }}
                    {{ $t("warehouse.pallet_packing_tips") }}</a-typography-text
                  >
                </a-col>
              </a-row>
            </template>
          </a-table>
        </div>

        <!-- 选择交付方式和费用明细 -->
        <div
          v-if="
            plan.outWarehouseType != outWarehouseTypeEnum.destruction &&
            plan.outWarehouseType !=
              outWarehouseTypeEnum.replaceSendWarehouseEntry &&
            plan.outWarehouseStatus == outPlanStatusEnum.inThePlan
          "
          class="mt-1"
        >
          <div class="mb-2 mt-3">
            <strong>{{ $t("warehouse.transportation_delivery_way") }}: </strong>
            <a-select
              v-model:value="slectedDeliveryWay"
              :placeholder="
                $t('warehouse.please_select_transportation_or_delivery_way')
              "
              @change="handleChangeDeliveryWay"
              style="min-width: 150px"
            >
              <a-select-option
                v-for="(item, index) in transDeliverWayEnum"
                :key="index"
                :value="item"
                :disabled="
                  item == transDeliverWayEnum.cwmLogistics &&
                  !isSupportedSeLogistics
                "
              >
                {{ $t($enumLangkey("transportationDeliveryWay", item)) }}
              </a-select-option>
            </a-select>
            <a-select
              v-if="slectedDeliveryWay == transDeliverWayEnum.takeTheir"
              class="ml-3 mt-2"
              v-model:value="selfMentionType"
              @change="isSelectedLogistics = true"
              :placeholder="$t('warehouse.please_select_the_pick_up_method')"
              :loading="takeTheirLoading"
              :disabled="disabledTakeTheir"
              style="min-width: 150px"
            >
              <a-select-option
                v-for="(item, index) in selfMentionTypeEnum"
                :key="index"
                :value="item"
              >
                {{ $t($enumLangkey("selfMentionType", item)) }}
              </a-select-option>
            </a-select>
          </div>
          <div
            v-if="slectedDeliveryWay == transDeliverWayEnum.cwmLogistics"
            class="mb-2"
          >
            <a-button
              type="primary"
              ghost
              v-if="isSupportedSeLogistics"
              :loading="freightEstimateLoading"
              @click="handleFreightEstimate"
              >{{ $t("warehouse.acquisition_logistics") }}</a-button
            >
          </div>
          <div
            v-if="
              slectedDeliveryWay == transDeliverWayEnum.takeTheir &&
              selfMentionType == selfMentionTypeEnum.truck
            "
            class="mb-2"
          >
            <a-form layout="inline">
              <a-form-item
                :label="$t('warehouse.reserved_take_goods_info')"
                style="min-width: 250px"
              >
                <a-input
                  v-model:value="takeGoodsInfo"
                  :placeholder="
                    $t('warehouse.only_allow_input_letter_and_number')
                  "
                />
              </a-form-item>
              <a-form-item>
                <c-tooltip :title="$t('warehouse.reserved_info_title')" />
              </a-form-item>
            </a-form>
          </div>
        </div>

        <!-- 物流方案 -->
        <div
          v-if="
            slectedDeliveryWay == transDeliverWayEnum.cwmLogistics &&
            isEstimatedCost
          "
        >
          <div class="mb-5">
            <a-tabs @change="handleChangeTabs">
              <a-tab-pane
                v-if="
                  list &&
                  list.length > 0 &&
                  selectedLogisticsType != feeCalculationMethodEnum.truckApi
                "
                :key="feeCalculationMethodEnum.template"
                :forceRender="true"
                :tab="$t('logistics.logistics')"
              >
                <a-table
                  :columns="columnsLogisticsScheme"
                  :data-source="list"
                  :scroll="{ x: true, y: 1000 }"
                  size="small"
                  :pagination="false"
                  :loading="logisticsSchemeLoading"
                >
                  <template #logisticsScheme>
                    <span>
                      {{ $t("warehouse.logistics_scheme") }} /
                      {{ $t("warehouse.logistics_code") }}
                    </span>
                  </template>

                  <template #logisticsWay>
                    <span>
                      {{ $t("warehouse.logistics_type") }}
                    </span>
                  </template>

                  <template #deliveryWay>
                    <span>
                      {{ $t("warehouse.delivery_way") }}
                    </span>
                  </template>

                  <template #referenceAging>
                    <span>
                      {{ $t("warehouse.reference_aging") }}
                    </span>
                  </template>
                  <template #freights>
                    <span>
                      {{ $t("warehouse.freight_estimate") }}
                    </span>
                  </template>

                  <template #details>
                    <span>
                      {{ $t("common.details") }}
                    </span>
                  </template>
                  <template #optionalServices>
                    <span>
                      {{ $t("warehouse.optional_services") }}
                    </span>
                  </template>

                  <template #select="{ record }" v-if="!isSelectedLogistics">
                    <a-button @click="handleSelectedLogistic(record)">{{
                      $t("common.select")
                    }}</a-button>
                  </template>

                  <template #logisticsSchemeCustom="{ record }">
                    <div class="text-warning">
                      {{ record.scheme }} / {{ record.code }}
                    </div>
                    <a-row :gutter="[8, 8]">
                      <a-col
                        v-for="item in record.descriptionItems"
                        :key="item"
                      >
                        <CheckCircleOutlined
                          class="text-warning mr-1"
                          :style="{ fontSize: '12px' }"
                        />
                        <span
                          style="font-size: 12px; color: rgba(102, 102, 102, 1)"
                        >
                          {{ item }}
                        </span>
                      </a-col>
                    </a-row>
                  </template>

                  <template #logisticsWayCustom="{ record }">
                    {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
                  </template>

                  <template #deliveryWayCustom="{ record }">
                    {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
                  </template>

                  <template #referenceAgingCustom="{ record }">
                    <div>
                      {{ record.daysMin }}-{{ record.daysMax }}
                      {{ $t("warehouse.workday") }}
                      ({{ $t($enumLangkey("aging", record.channelTimeType)) }})
                    </div>
                  </template>

                  <template #freightsCustom="{ record }">
                    <div class="text-warning">
                      {{ $t("warehouse.average_unit_price") }}:
                      {{ record.currencySymbol
                      }}{{ $filters.amountToFixed2(record.averagePrice) }}
                    </div>
                    <div class="text-warning">
                      {{ $t("warehouse.total_prices") }}:
                      {{ record.currencySymbol
                      }}{{ $filters.amountToFixed2(record.expressFee) }}
                    </div>
                  </template>

                  <template #detailsCustom="{ record }">
                    <div v-if="record.logisticsCalcMethod == 0">
                      {{ $t("logistics.chargeable_weight") }}:
                      {{ record.chargeableWeight }}kg
                    </div>
                    <div v-else>
                      {{ $t("logistics.chargeable_volume") }}:
                      {{ record.chargeableVolume }}m<sup>3</sup>
                    </div>
                    <div>
                      {{ $t("logistics.volume_weight_coefficient")
                      }}{{ record.volumeWeightParam }}
                    </div>
                  </template>

                  <template #optionalServicesCustom="{ record }">
                    <div v-if="isSelectedLogistics">
                      <div v-if="record.logisticsRegionInfo?.isDdpSupported">
                        <a-checkbox v-model:checked="record.isChooseDdp">
                          DDP ({{ record.currencySymbol }}
                          {{
                            $filters.amountToFixed2(
                              record.logisticsRegionInfo.ddpFee
                            )
                          }}
                          )
                        </a-checkbox>
                      </div>

                      <div v-if="record.logisticsRegionInfo?.isSignSupported">
                        <CSignSupported
                          v-model:value="record.signatureType"
                          :symbol="record.currencySymbol"
                          :signFeeOptions="
                            record.logisticsRegionInfo.signFeeDtos
                          "
                          :type="'editable'"
                        />
                      </div>
                    </div>

                    <div v-else>
                      <div v-if="record.logisticsRegionInfo?.isDdpSupported">
                        DDP: (
                        {{
                          $filters.formatCurrencyAmount(
                            record.currencySymbol,
                            record.logisticsRegionInfo.ddpFee,
                            false
                          )
                        }}
                        )
                      </div>

                      <div v-if="record.logisticsRegionInfo?.isSignSupported">
                        <CSignSupported
                          :symbol="record.currencySymbol"
                          :signFeeOptions="
                            record.logisticsRegionInfo?.signFeeDtos
                          "
                          :type="'preview'"
                        />
                      </div>
                    </div>

                    <span
                      v-if="
                        !record.logisticsRegionInfo?.isDdpSupported &&
                        !record.logisticsRegionInfo?.isSignSupported
                      "
                    >
                      -
                    </span>
                  </template>
                </a-table>
              </a-tab-pane>

              <a-tab-pane
                v-if="
                  truckList &&
                  truckList.length > 0 &&
                  selectedLogisticsType != feeCalculationMethodEnum.template
                "
                :key="feeCalculationMethodEnum.truckApi"
                :tab="$t('logistics.truck')"
                force-render
              >
                <a-table
                  :columns="columnsTruckScheme"
                  :data-source="truckList"
                  :scroll="{ x: 1000, y: 1000 }"
                  size="small"
                  :pagination="false"
                  :loading="logisticsSchemeLoading"
                >
                  <template #quoteOptionsTitle>
                    <a-popover placement="top">
                      <template #content>
                        {{ $t("logistics.quote_options_tips") }}
                      </template>
                      <span>{{ $t("logistics.quote_options") }}</span>
                      <span class="ml-1">
                        <QuestionCircleOutlined />
                      </span>
                    </a-popover>
                  </template>

                  <template #logisticsSchemeCustom="{ record }">
                    <div class="text-warning">
                      {{ record.scheme }} / {{ record.code }}
                    </div>
                    <a-row :gutter="[8, 8]">
                      <a-col
                        v-for="item in record.descriptionItems"
                        :key="item"
                      >
                        <CheckCircleOutlined
                          class="text-warning mr-1"
                          :style="{ fontSize: '12px' }"
                        />
                        <span
                          style="font-size: 12px; color: rgba(102, 102, 102, 1)"
                        >
                          {{ item }}
                        </span>
                      </a-col>
                    </a-row>
                  </template>

                  <template #logisticsWayCustom="{ record }">
                    {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
                  </template>
                  <template #deliveryWayCustom="{ record }">
                    {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
                  </template>

                  <template #quoteOptions="{ record }">
                    <div>
                      <a-form-item :label="$t('logistics.quote_type')">
                        <a-select
                          :disabled="isSelectedLogistics"
                          v-model:value="record.quoteModel.quoteTypeId"
                          style="max-width: 250px"
                          @change="handleChangeQuoteType(record.quoteModel)"
                          :loading="DropdownList.loading"
                        >
                          <a-select-option
                            v-for="(
                              item, index
                            ) in DropdownList?.quotationTypeList"
                            :key="index"
                            :value="item.id"
                            :option="item.needService"
                          >
                            {{ item.name }}</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                      <a-form-item
                        :label="$t('logistics.service_level')"
                        v-if="record.quoteModel.needService"
                      >
                        <a-select
                          :disabled="isSelectedLogistics"
                          v-model:value="record.quoteModel.serviceLevel"
                          @change="changeTrackQuoteOptions(record.quoteModel)"
                          style="max-width: 250px"
                          :loading="DropdownList.loading"
                        >
                          <a-select-option
                            v-for="(
                              item, index
                            ) in DropdownList.serviceLevelList"
                            :key="index"
                            :value="item.id"
                            >{{ item.name }}</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                      <a-form-item :label="$t('logistics.additional_options')">
                        <a-select
                          :disabled="isSelectedLogistics"
                          :allowClear="true"
                          style="max-width: 250px"
                          v-model:value="record.quoteModel.accessorials"
                          mode="multiple"
                          :loading="DropdownList.loading"
                          :show-search="true"
                          option-filter-prop="search-key"
                          @change="changeTrackQuoteOptions(record.quoteModel)"
                        >
                          <a-select-option
                            v-for="(
                              item, index
                            ) in DropdownList.additionalOptionsList"
                            :value="item.id"
                            :key="index"
                            :title="item.name"
                            :search-key="item.name"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                        <div class="mt-3 ml-5" v-if="!isSelectedLogistics">
                          <a-button
                            size="small"
                            :loading="DropdownList.loading"
                            @click="handleShowTrackFeeModal(record.quoteModel)"
                            :disabled="!isAllowEnquiry(record.quoteModel)"
                          >
                            {{ $t("logistics.inquiry_immediately") }}</a-button
                          >
                        </div>
                      </a-form-item>
                    </div>
                  </template>

                  <template #quoteDetails="{ record }">
                    <div v-if="record.quoteModel.trackFeeDetail">
                      <a-button
                        v-if="!isSelectedLogistics"
                        type="primary"
                        @click="handleSelectedTruck(record)"
                        >{{ $t("common.select") }}</a-button
                      >
                      {{ $t("logistics.quote") }}:
                      {{ record.quoteModel.trackFeeDetail.carrierName }}
                      [
                      {{ record.quoteModel.trackFeeDetail?.currencySymbol }}
                      {{
                        $filters.amountToFixed2(
                          record.quoteModel?.trackFeeDetail?.totalCharge
                        )
                      }}
                      ,
                      <!-- {{record.quoteModel.trackFeeDetail.transitTime}} -->
                      {{
                        isNaN(
                          parseInt(
                            record.quoteModel.trackFeeDetail.transitTime
                          ) + ""
                        )
                          ? record.quoteModel.trackFeeDetail.transitTime
                          : parseInt(
                              record.quoteModel.trackFeeDetail.transitTime
                            )
                      }}
                      {{ $t("common.days") }}
                      ]
                    </div>
                    <span v-else>-</span>
                  </template>
                </a-table>
              </a-tab-pane>
            </a-tabs>
            <div class="mt-3" v-if="isVatRequired">
              <span style="color: red">*</span>
              VAT:
              <a-input
                style="width: 200px"
                v-model:value="vatCode"
                allow-clear
              ></a-input>
            </div>
          </div>
        </div>

        <!-- 费用明细 -->
        <a-spin :spinning="feeLoading">
          <CFee
            :fees="calcTotal.fees"
            :totalFee="calcTotal.total"
            v-if="plan.outWarehouseStatus == outPlanStatusEnum.inThePlan"
          ></CFee>

          <!-- 确认出库按钮 -->
          <a-row
            type="flex"
            justify="end"
            class="mb-5 mt-3"
            v-if="plan.outWarehouseStatus == outPlanStatusEnum.inThePlan"
          >
            <a-col>
              <div style="width: 400px">
                <a-row type="flex" justify="center" :gutter="[24, 12]">
                  <a-col>
                    <a-button
                      :disabled="
                        (!isSelectedLogistics &&
                          plan.outWarehouseType !=
                            outWarehouseTypeEnum.replaceSendWarehouseEntry &&
                          plan.outWarehouseType !=
                            outWarehouseTypeEnum.destruction) ||
                        !totalFee?.symbol
                      "
                      type="primary"
                      @click="handleShowPayOrder"
                      >{{ $t("warehouse.confirm_out_warehouse_plan") }}
                    </a-button>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </a-spin>

        <Payment
          ref="refPayment"
          :orderNo="plan.no"
          :currencyId="totalFee?.currencyId ?? ''"
          :unpaidSymbol="totalFee?.symbol ?? ''"
          :unpaidSum="calcTotal.total?.value ?? 0"
          @handlePayFee="handleOrderPay"
          :loading="payModal.loading"
          v-model:visible="payModal.visible"
        ></Payment>

        <a-modal
          width="800px"
          v-model:visible="trackFeeModal.visible"
          :centered="true"
          :maskClosable="false"
          :ok-button-props="{ hidden: true }"
          :cancelText="$t('common.close')"
          :title="$t('logistics.select_quote')"
        >
          <a-table
            :columns="trackFeeColumns"
            :loading="DropdownList.loading"
            :data-source="trackFeeModal.list"
            :scroll="{ x: 500, y: 600 }"
            size="small"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            :pagination="{
              defaultPageSize: trackFeeModal.pageData.currentPageSize,
              hideOnSinglePage: true,
              current: trackFeeModal.pageData.currentPageIndex,
            }"
            @change="
              (page) => {
                trackFeeModal.pageData.currentPageIndex = page.current;
              }
            "
          >
            <template #carrier>
              {{ $t("logistics.carrier") }}
            </template>
            <template #price>
              {{ $t("logistics.price") }}
            </template>
            <template #aging>
              {{ $t("logistics.aging") }}({{ $t("common.days") }})
            </template>

            <template #priceC="{ record }">
              {{ record.currencySymbol }}
              {{ $filters.amountToFixed2(record?.totalCharge) }}
            </template>
            <template #transitTime="{ record }">
              {{
                isNaN(parseInt(record.transitTime) + "")
                  ? record.transitTime
                  : parseInt(record.transitTime)
              }}
            </template>

            <template #select="{ record }">
              <div class="ant-form-inline">
                <a-button @click="handleSelectedFee(record)">{{
                  $t("common.select")
                }}</a-button>
              </div>
            </template>
          </a-table>
        </a-modal>
      </a-spin>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref, nextTick, computed } from "vue";
import {
  Row,
  Col,
  Steps,
  Tag,
  Menu,
  Spin,
  Radio,
  Popover,
  Table,
  Input,
  Typography,
  Tooltip,
  Tabs,
  Checkbox,
  Select,
  Button,
  Form,
  Modal,
  Space,
  message,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import Payment from "../../components/Payment.vue";
import CHaveProductInformation from "../../components/CHaveProductInformation.vue";
import CFee from "../../components/CFee.vue";
import CTooltip from "@/views/components/CTooltip.vue";
import CFileDisplay from "../../components/CFileDisplay.vue";
import CTagMixedOrSingle from "../../components/CTagMixedOrSingle.vue";
import CSignSupported from "../../components/CSignSupported.vue";
import {
  outWarehouseType as outWarehouseTypeEnum,
  transportationDeliveryWay as transDeliverWayEnum,
  selfMentionType as selfMentionTypeEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
  outPlanStatus as outPlanStatusEnum,
} from "../../../enum/enum.json";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useSupplierHelper } from "../../../utils/supplierHelper";
import {
  getOutPlanDetails,
  updateOutPlanName,
  confirmOutPlan,
  getFreightEstimate,
  getLogisticDetail,
  checkSupportConsignment,
  getOutPlanFee,
  getTrackLogisticsFee,
} from "@/api/modules/storage/index";
import {
  getAddressByLanguageV2,
  cmCubicToM,
  getLinkman,
  gToKg,
} from "../../../utils/general";
import { useRouter, useRoute } from "vue-router";
import { useTab } from "@/hooks/tabs/index";
import { cloneDeep } from "lodash-es";
import filters from "@/filters";
export default {
  name: "storage_confirm_out_plan",
  components: {
    CTagMixedOrSingle,
    CHaveProductInformation,
    Content,
    CFee,
    Payment,
    CTooltip,
    CFileDisplay,
    ASpin: Spin,
    ATag: Tag,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ATypographyText: Typography.Text,
    ATooltip: Tooltip,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ASpace: Space,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    APopover: Popover,
    ACheckbox: Checkbox,
    Content,
    CFee,
    Payment,
    CTooltip,
    CFileDisplay,
    CSignSupported,
  },
  setup() {
    const i18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const refPayment = ref(null);
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();
    const refInputPlanName = ref();
    const supplierHelper = useSupplierHelper();

    const innerColumns = [
      { width: 60 },
      {
        title: () => i18n.t("warehouse.product_info"),
        slots: {
          customRender: "product_info",
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
      },
      {
        title: () => i18n.t("common.count"),
        slots: {
          customRender: "count",
        },
      },
    ];

    const columns = [
      {
        dataIndex: "serialNumber ",
        slots: {
          title: "serialNumber",
        },
        customRender: ({ text, record, index }) => {
          return (
            (currentPageIndex.value - 1) * defaultPageSize.value + (index + 1)
          );
        },
        width: 60,
      },
      {
        slots: {
          title: "boxNoTitle",
          customRender: "boxNo",
        },
        width: 150,
      },
      {
        title: "",
        width: 50,
      },
      {
        title: () => i18n.t("warehouse.encasement_count"),
        slots: {
          customRender: "containerCount",
        },
        width: 120,
      },
      {
        title: () => i18n.t("warehouse.out_warehouse_info"),
        slots: {
          customRender: "out_warehouse_info",
        },
        width: 200,
      },
      {
        dataIndex: "lableServe",
        slots: {
          title: "lableServe",
          customRender: "lableServeCustom",
        },
        width: 200,
      },
    ];

    //物流column基础(最齐全的column)
    const columnsLogisticsSchemeBasis = [
      {
        title: "",
        dataIndex: "select",
        slots: {
          customRender: "select",
        },
        width: 80,
      },
      {
        dataIndex: "logisticsScheme",
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
        width: 180,
      },
      {
        dataIndex: "logisticsWay", //物流类型
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
        width: 70,
      },
      {
        dataIndex: "deliveryWay", //交付方式
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
        width: 70,
      },
      {
        dataIndex: "referenceAging", //参考时效
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
        width: 90,
      },
      {
        dataIndex: "freights", //运费
        slots: {
          title: "freights",
          customRender: "freightsCustom", //平均单价和合计
        },
        width: 100,
      },
      {
        dataIndex: "details", //详情
        slots: {
          title: "details",
          customRender: "detailsCustom", //计费重、体积重系数：除600
        },
        width: 100,
      },
      {
        dataIndex: "optionalServices", //可选服务
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
        width: 180,
      },
    ];

    const columnsTruckScheme = [
      {
        title: () =>
          i18n.t("warehouse.logistics_scheme") +
          " / " +
          i18n.t("warehouse.logistics_code"),
        slots: {
          customRender: "logisticsSchemeCustom",
        },
        width: 100,
      },
      {
        title: () => i18n.t("warehouse.logistics_type"),
        slots: {
          customRender: "logisticsWayCustom",
        },
        width: 50,
      },
      {
        title: () => i18n.t("warehouse.delivery_way"),
        slots: {
          customRender: "deliveryWayCustom",
        },
        width: 50,
      },
      {
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
        width: 150,
      },
      {
        title: () => i18n.t("logistics.quote_details"),
        slots: {
          customRender: "quoteDetails",
        },
        width: 100,
      },
    ];

    const state = reactive({
      columns: columns,
      columnsLogisticsScheme: columnsLogisticsSchemeBasis,
      pageLoading: false,
      buttonSavePlanNameLoading: false,
      freightEstimateLoading: false,
      logisticsSchemeLoading: false,
      feeLoading: false,
      isChangingPlanName: false,
      tempPlanName: "",
      slectedDeliveryWay: null,
      selfMentionType: null,
      disabledTakeTheir: false,
      takeTheirLoading: false,
      plan: {
        planId: "",
        planName: "",
        inventoryWarehouseAddress: {
          //出库仓库
          no: "",
        },
        targetAddress: {
          //目标库
          warehouseType: "",
          warehouseNo: "",
          id: "", //仓库id
        },
        outWarehouseType: "",
        amazonShippingNumber: "",
        shipmentTrackingNumber: "",

        tableList: [],
        totalBox: "-",
        outTotalVolume: "-",
        outTotalWeight: "-",
        transportCharacteristics: null,
        isSelectHitServe: false,
        truckRecord: null,
      },
      takeGoodsInfo: null,
      truckList: [], //卡车物流方案
      list: [], //模板计算方案
      fees: [],
      totalFee: {
        currencyId: null,
        symbol: null,
        value: null,
      },
      isEstimatedCost: false, //是否预估了费用
      isSupportedSeLogistics: false,
      payModal: {
        visible: false,
        loading: false,
      },
      isVatRequired: null,
      vatCode: null,
      isSelectedLogistics: null,
      selectedLogisticsType: null, //选择的物流:物流或卡车
    });

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    //获取页面详情
    const funcOutPlanDetails = (planId) => {
      state.pageLoading = true;
      return getOutPlanDetails({ id: planId })
        .then((res) => {
          let r = res.result;
          state.plan.inventoryWarehouseAddress = r.from;
          state.plan.targetAddress = r.to;

          state.plan.planId = r.id;
          state.plan.planName = r.planName;
          state.plan.planNo = r.planNo;
          state.plan.warehouseNo = r.from.no;
          state.plan.outWarehouseStatus = r.outWarehouseStatus;
          state.plan.outWarehouseType = r.outWarehouseType;

          state.plan.amazonShippingNumber = r.fbaAmazonTransNo;
          state.plan.shipmentTrackingNumber = r.fbaTranceNo;
          state.plan.isWarehouseLabeling = r.isWarehouseLabelProcessing;

          state.plan.totalBox = r.total.plan.totalContainerBoxCount;
          state.plan.outTotalVolume = cmCubicToM(r.total.plan.totalVolume);
          state.plan.outTotalWeight = gToKg(r.total.plan.totalWeight);
          state.plan.transportCharacteristics =
            r.total.transportCharacteristics;
          state.plan.isSelectHitServe = r.daycareServiceId;

          if (
            state.plan.outWarehouseType ==
            outWarehouseTypeEnum.betweenWarehouseAllocate ||
            state.plan.outWarehouseType ==
            outWarehouseTypeEnum.destruction
          ) {
            state.columns = columns.filter((x) => x.dataIndex != "lableServe");
          }

          state.plan.tableList = r.items;
          state.pageLoading = false;
        })
        .catch(() => {
          state.pageLoading = false;
        });
    };

    //------------修改计划名称 start--------------------------------
    const handleSavePlanName = async () => {
      let par = {
        planId: state.plan.planId,
        planName: state.plan.planName,
      };
      state.buttonSavePlanNameLoading = true;
      updateOutPlanName(par)
        .then((res) => {
          message.success(i18n.t("common.amend_success"));
          state.isChangingPlanName = false;
        })
        .finally(() => {
          state.buttonSavePlanNameLoading = false;
        });
    };

    const handleCancelAlterPlanName = async () => {
      state.plan.planName = state.tempPlanName;
      state.isChangingPlanName = false;
    };

    const handleButtonAlterPlanName = async () => {
      state.isChangingPlanName = true;
      state.tempPlanName = state.plan.planName;
      nextTick(() => {
        refInputPlanName.value.focus();
      });
    };
    //------------修改计划名称 end--------------------------------

    const handleChangeDeliveryWay = () => {
      state.fees = null;
      state.totalFee = null;

      state.vatCode = null;
      state.isVatRequired = false;
      state.isSelectedLogistics = false;
      state.selfMentionType = null;

      if (state.slectedDeliveryWay === transDeliverWayEnum.takeTheir) {
        // 自提操作下
        funcGetOutPlanFee();
      } else {
        handleFreightEstimate();
      }
    };

    //获取费用: 卡车\物流\自提都通过这个获取费用
    const funcGetOutPlanFee = (record) => {
      state.takeTheirLoading = true;
      state.disabledTakeTheir = false;
      state.feeLoading = true;
      state.freightEstimateLoading = true;
      let parameter = {
        id: route.params.id,
        transDeliverType: state.slectedDeliveryWay,
      };

      if (
        record &&
        state.selectedLogisticsType == feeCalculationMethodEnum.template
      ) {
        parameter.logistics = {
          logisticsId: record.id,
          commonLogistics: {
            isChooseDdp: record?.isChooseDdp,
            isChooseSign: record?.signatureType > 0,
            signatureType: record?.signatureType,
          },
        };
      } else if (
        record &&
        state.selectedLogisticsType == feeCalculationMethodEnum.truckApi
      ) {
        //卡车的参数:
        parameter.logistics = {
          logisticsId: record.id,
          truckApiLogistics: {
            quoteDetailId:
              state.truckRecord.quoteModel?.trackFeeDetail?.quoteDetailId,
            quoteTypeId: state.truckRecord.quoteModel?.quoteTypeId,
            serviceLevel: state.truckRecord.quoteModel?.serviceLevel,
            accessorials: state.truckRecord.quoteModel?.accessorials,
          },
        };
      }

      getOutPlanFee(parameter)
        .then(({ result }) => {
          if (result) {
            state.fees = result.fees;
            state.totalFee = result.totalFee;
          }
          state.isSelectedLogistics = true;
          state.takeTheirLoading = false;
          state.feeLoading = false;
          state.freightEstimateLoading = false;
        })
        .catch(() => {
          state.disabledTakeTheir = true;
          state.takeTheirLoading = false;
          state.feeLoading = false;
          state.freightEstimateLoading = false;
        });
    };

    //-------------------卡车渠道 下拉列表及报价 start--------------------------
    const getQuoteModel = (x) => {
      return {
        quoteTypeId: null,
        serviceLevel: null,
        accessorials: [],
        logisticId: x?.logisticsBaseInfo?.id,
        planId: state.plan.planId,
        needService: false,
        trackFeeDetail: null,
      };
    };

    const DropdownList = reactive({
      quotationTypeList: [],
      serviceLevelList: [],
      additionalOptionsList: [],
      loading: false,
    });

    //缓存例子-cy
    var quotesCacheObj = {};

    var getCacheKey = (paras) => {
      return (
        paras.quoteTypeId +
        ";" +
        paras.serviceLevel +
        ":" +
        paras.logisticId +
        "," +
        (paras.accessorials || []).sort().join("-")
      );
    };

    const handleChangeTabs = (key) => {
      if (key == feeCalculationMethodEnum.truckApi) {
        funcGetDropdownList();
      }
    };

    const funcGetDropdownList = () => {
      DropdownList.loading = true;
      Promise.all([
        supplierHelper.getQuoteTypeList(),
        supplierHelper.getServiceLevelList(),
        supplierHelper.getAdditionalOptionsList(),
      ])
        .then((result) => {
          if (result) {
            DropdownList.quotationTypeList = result[0];
            DropdownList.serviceLevelList = result[1];
            DropdownList.additionalOptionsList = result[2];
          }
          DropdownList.loading = false;
        })
        .catch(() => {
          DropdownList.loading = false;
        });
    };

    const isAllowEnquiry = (quoteModel) => {
      if (!quoteModel.quoteTypeId) {
        return false;
      } else {
        if (quoteModel.needService && !quoteModel.serviceLevel) {
          return false;
        }
        return true;
      }
    };

    const changeTrackQuoteOptions = (quoteModel) => {
      if (quoteModel?.trackFeeDetail) {
        quoteModel.trackFeeDetail = null;
      }
    };

    const handleChangeQuoteType = (quoteModel) => {
      quoteModel.serviceLevel = null;
      quoteModel.needService = DropdownList?.quotationTypeList.find(
        (x) => x.id == quoteModel.quoteTypeId
      ).needService;
      changeTrackQuoteOptions(quoteModel);
    };

    const hanldeGetTrackLogisticsFee = (quoteModel) => {
      var cacheKey = getCacheKey(quoteModel);
      if (quotesCacheObj[cacheKey]) {
        trackFeeModal.list = quotesCacheObj[cacheKey];
        return;
      }

      DropdownList.loading = true;
      trackFeeModal.list = [];
      getTrackLogisticsFee({ ...quoteModel })
        .then(({ result }) => {
          if (result) {
            trackFeeModal.list = result;
            quotesCacheObj[cacheKey] = result;
          } else {
            trackFeeModal.quoteModel.currencySymbol = null;
            trackFeeModal.quoteModel.totalCharge = null;
          }
          DropdownList.loading = false;
        })
        .catch(() => {
          DropdownList.loading = false;
        });
    };

    const trackFeeModal = reactive({
      visible: false,
      list: [],
      quoteModel: null,
      pageData: {
        currentPageIndex: 1,
        currentPageSize: 10,
      },
    });

    const handleShowTrackFeeModal = (quoteModel) => {
      trackFeeModal.visible = true;
      trackFeeModal.quoteModel = quoteModel;
      trackFeeModal.pageData.currentPageIndex = 1;
      hanldeGetTrackLogisticsFee(quoteModel);
    };

    const handleSelectedFee = (record) => {
      trackFeeModal.quoteModel.trackFeeDetail = record;
      trackFeeModal.visible = false;
    };

    const trackFeeColumns = [
      {
        dataIndex: "carrierName",
        slots: {
          title: "carrier",
        },
        sorter: (a, b) => {
          return (a.carrierName || "").localeCompare(b.carrierName);
        },
        width: 250,
      },
      {
        title: () => i18n.t("logistics.price"),
        slots: {
          customRender: "priceC",
        },
        sorter: (a, b) => a.totalCharge - b.totalCharge,
        width: 150,
      },
      {
        dataIndex: "transitTime",
        slots: {
          title: "aging",
          customRender: "transitTime",
        },
        sorter: (a, b) => a.transitTime - b.transitTime,
        width: 100,
      },
      {
        title: "",
        slots: {
          customRender: "select",
        },
        fixed: "right",
        width: 100,
      },
    ];

    //-------------------卡车渠道 下拉列表及报价 end--------------------------

    //-------------------获取物流方案和选择物流 start--------------------------
    const handleFreightEstimate = () => {
      let pra = { planId: state.plan.planId };
      state.isSelectedLogistics = false;
      state.selectedLogisticsType = null;
      state.isVatRequired = false;
      state.fees = null;
      state.totalFee = null;

      state.freightEstimateLoading = true;
      state.logisticsSchemeLoading = true;
      state.isEstimatedCost = true;
      getFreightEstimate(pra)
        .then((res) => {
          state.list = [];
          state.truckList = [];
          let tempList = [];
          if (res.result && res.result.length > 0) {
            tempList = res.result.map((x, index) => {
              return {
                key: index,
                id: x.logisticsBaseInfo.id,
                scheme: x.logisticsBaseInfo.name,
                code: x.logisticsBaseInfo.code,
                descriptionItems: x.logisticsBaseInfo.descriptionItems,

                logisticsWay: x.logisticsBaseInfo.logisticsType,
                deliveryWay: x.logisticsBaseInfo.deliveryType,

                daysMin: x.logisticsBaseInfo.daysMin,
                daysMax: x.logisticsBaseInfo.daysMax,
                channelTimeType: x.logisticsBaseInfo.channelTimeType, //签收或自提 枚举

                averagePrice: x.logisticsBaseInfo.averagePrice, //平均费
                expressFee: x.logisticsBaseInfo.totalFeeWithoutServices, //合计费用
                currencySymbol: x.logisticsBaseInfo.currencySymbol, //货号符号

                chargeableWeight: (
                  x.logisticsBaseInfo.chargeableWeight / 1000
                ).toFixed(3), //计费重
                volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam, //体积系数
                chargeableVolume: cmCubicToM(
                  x.logisticsBaseInfo.chargeableVolume
                ),
                logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,
                logisticsRegionInfo: x.logisticsRegionInfo,

                feeCalculationMethod: x.logisticsBaseInfo.feeCalculationMethod,
                quoteModel: getQuoteModel(x),
              };
            });

            state.list = tempList.filter(
              (x) => x.feeCalculationMethod != feeCalculationMethodEnum.truckApi
            );
            state.truckList = tempList.filter(
              (x) => x.feeCalculationMethod == feeCalculationMethodEnum.truckApi
            );
            state.columnsLogisticsScheme = columnsLogisticsSchemeBasis;
          } else {
            state.isSupportedSeLogistics = false;
            state.isEstimatedCost = false;
            message.error(i18n.t("logistics.nonsupport_logistics"));
            state.slectedDeliveryWay = transDeliverWayEnum.takeTheir;

            //不支持物流,就自动选了自提,这个时候需要获取一下费用
            handleChangeDeliveryWay();
          }
        })
        .finally(() => {
          state.freightEstimateLoading = false;
          state.logisticsSchemeLoading = false;
        });
    };

    //判断是否支持物流
    const checkSupportedSeLogistics = () => {
      let pra = {
        warehouseId: state.plan.inventoryWarehouseAddress.id,
        warehouseType: "4", //1 : 存储仓, 2 : 配送仓, 4 : 物流中心
      };
      checkSupportConsignment(pra).then((res) => {
        state.isSupportedSeLogistics = res.result ? true : false;
      });
    };

    const handleSelectedLogistic = (record) => {
      //获取费用详情和费用
      state.logisticsSchemeLoading = true;
      state.freightEstimateLoading = true;
      state.columnsLogisticsScheme = columnsLogisticsSchemeBasis.filter(
        (x) => x.dataIndex != "select"
      );
      getLogisticDetail(record.id, state.plan.planId)
        .then(({ result }) => {
          //更新一下运费预估(平均单价和合计)
          record.averagePrice = result.logisticsBaseInfo.averagePrice;
          record.expressFee = result.logisticsBaseInfo.totalFeeWithoutServices;

          state.logisticsSchemeLoading = false;
          state.freightEstimateLoading = false;

          state.vatCode = null;
          state.isVatRequired = result.logisticsRegionInfo?.isVatRequired;

          state.isSelectedLogistics = true;
          state.selectedLogisticsType = feeCalculationMethodEnum.template;
          state.list = state.list?.filter((x) => x.id == record.id);
          //这行代码很重要.是否支持ddp什么的,以这个接口返回为主
          state.list[0].logisticsRegionInfo = result.logisticsRegionInfo;
          funcGetOutPlanFee(record);
        })
        .catch(() => {
          state.logisticsSchemeLoading = false;
          state.freightEstimateLoading = false;
        });
    };

    const handleSelectedTruck = (record) => {
      state.selectedLogisticsType = feeCalculationMethodEnum.truckApi;
      state.isSelectedLogistics = true;
      state.truckRecord = record;
      state.truckList = state.truckList?.filter((x) => x.id == record.id);
      funcGetOutPlanFee(record);
    };
    //-------------------获取物流方案和选择物流 end--------------------------

    //----------------订单支付 start----------------------------------------
    const handleShowPayOrder = async () => {
      if (
        state.plan.outWarehouseType !=
        outWarehouseTypeEnum.replaceSendWarehouseEntry &&
        state.plan.outWarehouseType !=
        outWarehouseTypeEnum.destruction
      ) {
        if (!state.slectedDeliveryWay) {
          message.error(
            i18n.t("common.p0_is_required", [
              i18n.t("logistics.logistics_delivery_way"),
            ])
          );
          return false;
        }
        if (state.slectedDeliveryWay == transDeliverWayEnum.takeTheir) {
          if (!state.selfMentionType) {
            message.error(i18n.t("warehouse.please_select_the_pick_up_method"));
            return false;
          }

          if (
            state.selfMentionType == selfMentionTypeEnum.truck &&
            (!state.takeGoodsInfo || state.takeGoodsInfo.trim().length == 0)
          ) {
            message.error(
              i18n.t("common.p0_is_required", [
                i18n.t("warehouse.reserved_take_goods_info"),
              ])
            );
            return false;
          }
        }

        if (state.slectedDeliveryWay == transDeliverWayEnum.cwmLogistics) {
          if (state.isVatRequired && !state.vatCode) {
            message.error(i18n.t("common.p0_is_required", ["VAT"]));
            return false;
          }
        }
      }
      state.payModal.visible = true;
      refPayment.value.handleShowPayOrder();
    };

    const handleOrderPay = async () => {
      let par = {
        id: state.plan.planId,
        payFee: filters.amountToFixed2(calcTotal.value?.total?.value),
        transDeliverType: state.slectedDeliveryWay,
        transSelfMentionDistinguish:
          state.takeGoodsInfo && state.takeGoodsInfo.trim(),
        selfMentionType: state.selfMentionType,
      };

      if (state.selectedLogisticsType == feeCalculationMethodEnum.template) {
        par.logistics = {
          logisticsId: state.list[0]?.id,
          commonLogistics: {
            isChooseDdp: state.list[0]?.isChooseDdp,
            isChooseSign: state.list[0]?.signatureType > 0,
            signatureType: state.list[0]?.signatureType,
            vatCode: state.vatCode,
          },
          truckApiLogistics: null,
        };
      }

      if (state.selectedLogisticsType == feeCalculationMethodEnum.truckApi) {
        par.logistics = {
          logisticsId: state.truckRecord.id,
          truckApiLogistics: {
            quoteDetailId:
              state.truckRecord.quoteModel?.trackFeeDetail?.quoteDetailId,
            quoteTypeId: state.truckRecord.quoteModel?.quoteTypeId,
            serviceLevel: state.truckRecord.quoteModel?.serviceLevel,
            accessorials: state.truckRecord.quoteModel?.accessorials,
          },
        };
      }

      state.payModal.loading = true;
      confirmOutPlan(par)
        .then((res) => {
          state.payModal.visible = false;
          message.success(i18n.t("common.succeed"));
          delVisitedRoute(router.currentRoute.value);
          router.push({ path: "/storage/outplan" });
          state.payModal.loading = false;
        })
        .catch(() => {
          state.payModal.loading = false;
        });
    };

    //----------------订单支付 end--------------------------------------------

    const calcTotal = computed(() => {
      //基础费用 详情数组(费用类型)
      let fees = cloneDeep(state.fees);
      //基础费用 金额
      let total = cloneDeep(state.totalFee);

      //计算详情数组:ddp,签名签收费用---物流模板
      if (
        state.isSelectedLogistics &&
        state.slectedDeliveryWay == transDeliverWayEnum.cwmLogistics &&
        state.selectedLogisticsType == feeCalculationMethodEnum.template
      ) {
        if (state.list?.length > 0) {
          //ddp
          if (state.list[0]?.isChooseDdp) {
            fees.push({
              symbol: total.symbol,
              value: state.list[0]?.logisticsRegionInfo?.ddpFee,
              feeType: state.list[0]?.logisticsRegionInfo?.ddpFeeType,
            });

            total.value =
              total.value + state.list[0]?.logisticsRegionInfo?.ddpFee ?? 0;
          }

          //签名签收
          if (state.list[0]?.signatureType) {
            let signature =
              state.list[0]?.logisticsRegionInfo?.signFeeDtos?.find(
                (x) => x.signatureType == state.list[0]?.signatureType
              );

            fees.push({
              symbol: total.symbol,
              value: signature?.fee,
              feeType: state.list[0]?.logisticsRegionInfo?.signFeeType,
            });

            total.value = total.value + signature?.fee ?? 0;
          }
        }
      }

      return {
        fees: fees,
        total: total,
      };
    });

    onMounted(() => {
      let planId = route.params.id;
      if (planId) {
        funcOutPlanDetails(planId).then(() => {
          //转入本地配送仓
          if (
            state.plan.outWarehouseType !=
            outWarehouseTypeEnum.replaceSendWarehouseEntry &&
            state.plan.outWarehouseType !=
            outWarehouseTypeEnum.destruction
          ) {
            checkSupportedSeLogistics();
          }

          if (
            state.plan.outWarehouseType ==
            outWarehouseTypeEnum.replaceSendWarehouseEntry ||
            state.plan.outWarehouseType ==
            outWarehouseTypeEnum.destruction
          ) {
            funcGetOutPlanFee();
          }
        });
      }
    });

    const currentPageIndex = ref(1);
    const defaultPageSize = ref(50);

    return {
      outPlanStatusEnum,
      feeCalculationMethodEnum,
      selfMentionTypeEnum,
      transDeliverWayEnum,
      outWarehouseTypeEnum,

      handleSavePlanName,

      innerColumns,
      getAddress,
      getLinkman,
      gToKg,

      currentPageIndex,
      defaultPageSize,

      DropdownList,

      ...toRefs(state),
      refInputPlanName,
      refPayment,
      handleCancelAlterPlanName,
      handleButtonAlterPlanName,

      handleFreightEstimate, //获取物流方案
      handleChangeDeliveryWay,

      //卡车
      columnsTruckScheme,
      trackFeeColumns,
      isAllowEnquiry,
      handleChangeQuoteType,
      hanldeGetTrackLogisticsFee,
      trackFeeModal,
      handleShowTrackFeeModal,
      handleSelectedFee,
      changeTrackQuoteOptions,

      handleChangeTabs,
      handleSelectedLogistic,
      handleSelectedTruck,

      funcGetOutPlanFee,
      handleShowPayOrder,
      handleOrderPay,

      calcTotal,
    };
  },
};
</script>

<style lang="less" scoped></style>
